<template>
  <div class="home">
      <div class="imgBox" v-if="isPc">
          <img class="maxImg" src="../assets/h5/backScreen.jpg">
          <div class="minImg_pc">
              <img src="../assets/h5/liriGomp.jpg" @click="toLirigo">
              <el-button class="buttonWords">微信扫一扫，使用小程序</el-button>
          </div>
          <div class="minImg_pc_right">
              <img src="../assets/h5/liriGo.jpg" @click="toLirigo">
          </div>
          
      </div>
      <div class="imgBox" v-if="!isPc">
          <img class="maxImg" src="../assets/h5/phoneScreen.jpg">
          <img class="minImg" src="../assets/h5/phoneButton.png" @click="toLirigo">
      </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
        isPc:false
    };
  },
  components: {
  },
  mounted(){
      this.isMobile()
  },
  methods:{
      isMobile() {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        this.isPc = false
        console.log('移动端打开')
      } else {
        this.isPc = true
        console.log('PC端打开')
      }
    },
      toLirigo(){
        //   location.href = "https://wxaurl.cn/qZEj2icWdPj"
        let params={
            path:'/pages/index/index'
        }
        axios.post('https://wechat.lirimall.com/small-inteface/small/link/generateUrlLink',params).then(res=>{
            location.href = res.data.body
            console.log(res)
        }).catch(err=>{
            console.log(err)
        })
      }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
}
.imgBox{
    width: 100%;
    height: 100%;
    position: relative;
}
.maxImg{
    width: 100%;
    height: 100%;
}
.minImg{
    position: absolute;
    width: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    bottom: 10%;
}
.minImg_pc{
    position: absolute;
    left: 20%;
    transform: translate(-50%,-50%);
    bottom: -10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.minImg_pc_right{
    position: absolute;
    right: 10%;
    transform: translate(-50%,-50%);
    bottom: -10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.buttonWords{
    border: none;
    width: 100%;
    border-radius: unset;
    font-weight: 500;
    font-size: 16px;
    color: black;
    font-family: fantasy;
}
</style>
